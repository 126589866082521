import React, { useState } from 'react';

import { LabData } from '../../../../../types/types';
import { Licensing } from '../../../../../types/types';
import LicenseDisplayBasic from '../license-display/license-display-basic';
import LicenseDisplayWithChildren from '../license-display/license-display-with-children';
import LicenseError from '../license-error/license-error';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import '../license-form.scss';
import { ChainalysisLicenseData } from '../successTextTransformers/chainalysisSuccessTextTransformer';

const DEFAULT_ERROR_MESSAGE =
    'An error has occured while trying to retrieve your license.';

interface PushbuttonLicenseProps {
    /** Extra information about the lab */
    labData: LabData;
    licensingIntegrationFn: Licensing.LicensingIntegrationFunction;
    /** Content controls */
    content: Licensing.LicenseIntegrationContent;
}

/**
 * A component for simple 'pushbutton' license requests,
 * where the user acquires their license after simply
 * pushing a button.
 */
const PushbuttonLicense = ({
    labData,
    licensingIntegrationFn,
    content,
}: PushbuttonLicenseProps) => {
    const [license, setLicense] = useState<string | null>(null);
    const [licenseData, setLicenseData] =
        useState<ChainalysisLicenseData | null>(null);
    const [licenseError, setLicenseError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionComplete, setSubmissionComplete] = useState(false);
    const { buttonText, bodyText, htmlBody, successText } = content;

    /**
     * Get a license by way of a provided function
     * `licensingIntegrationFn` prop.
     */
    const getLicense = async () => {
        try {
            setIsSubmitting(true);
            const licenseResponse = await licensingIntegrationFn(labData);
            if (
                'success' in licenseResponse &&
                'licenseCode' in licenseResponse
            ) {
                if (licenseResponse.licenseCode) {
                    setLicense(licenseResponse.licenseCode);
                } else if ('licenseData' in licenseResponse) {
                    // @ts-ignore
                    setLicenseData(licenseResponse.licenseData);
                }
            } else if (
                'licenseData' in licenseResponse &&
                'username' in licenseResponse.licenseData
            ) {
                setLicenseData(licenseResponse.licenseData);
                setLicenseError(null);
            }
        } catch (e) {
            if (e instanceof Error) {
                setLicenseError(e.message || DEFAULT_ERROR_MESSAGE);
            }
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            setIsSubmitting(false);
            setSubmissionComplete(true);
        }
    };

    return (
        <section className="license-form">
            {bodyText && (
                <section>
                    <p>{bodyText}</p>
                </section>
            )}
            {htmlBody && (
                <section>
                    <div dangerouslySetInnerHTML={ { __html: htmlBody } } />
                </section>
            )}
            <LicenseFormSubmitButton
                onClick={ () => getLicense() }
                disabled={
                    !!isSubmitting ||
                    !!licenseError ||
                    !!license ||
                    submissionComplete
                }
                submitting={ isSubmitting }
            >
                { buttonText }
            </LicenseFormSubmitButton>

            {/* Basic License Display */}
            {license && (
                <LicenseDisplayBasic
                    license={ license }
                    licenseText={ successText }
                />
            )}

            {/* Special Data display w/ transformer */}
            {licenseData && content.successTextTransformer && (
                <LicenseDisplayWithChildren>
                    {content.successTextTransformer(licenseData)}
                </LicenseDisplayWithChildren>
            )}

            {licenseError && <LicenseError error={ licenseError } />}
        </section>
    );
};

export default PushbuttonLicense;
