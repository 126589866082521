/**
 * @prettier
 */
import { delay, HttpResponse, http } from 'msw';

import appConfig from '../../config/appConfig';
import { Licensing } from '../../types/types';
import { TEST_ACCOUNT_RESPONSE } from '../account/account';

const config = appConfig();

/**
 * Default amount to delay responses by. This is used to prevent tests
 * from failing due to network responses that are too fast.
 */
const defaultDelayMs = 100;

/**
 * A common response structure from the SANS licensing service, used for
 * Arsenal, Maltego, and Shodan.
 */
export const mockStandardLicensingResponse: Licensing.StandardLicensingServiceResponse =
    {
        license: {
            id: TEST_ACCOUNT_RESPONSE.data.sansAccountID,
            user_id: TEST_ACCOUNT_RESPONSE.data.sansAccountID,
            email_address: TEST_ACCOUNT_RESPONSE.data.sansEmail,
            license_code: 'SANS12345678',
        },
    };

/**
 * A standardized response for license data. This is the structure returned by 
 * mylabs-service, and direct requests to licensing are transformed into this structure.
 */
export const mockLicensingResponseV2: Licensing.V2.LicensingServiceResponse = {
    success: true,
    licenseCode: 'test-license',
    licenseData: null,
    error: null,
};

export const chainalysisLicenseData: Licensing.VendorResponses.Chainalysis = {
    license: {
        user_id: TEST_ACCOUNT_RESPONSE.data.sansAccountID,
        email_address: TEST_ACCOUNT_RESPONSE.data.sansEmail,
        first_name: TEST_ACCOUNT_RESPONSE.data.name.split(' ')[0]!,
        last_name: TEST_ACCOUNT_RESPONSE.data.name.split(' ')[1]!,
        reactor_credentials: {
            username: 'foo',
            password: 'bar',
        },
    },
};

export const cellebriteLicenseData: Licensing.VendorResponses.Cellebrite = {
    license:
        // eslint-disable-next-line max-len
        'UEsDBBQAAAAAABZsM1QAAAAAAAAAAAAAAAANACAAVGVzdCBBcmNoaXZlL1VUDQAHfYPoYX2D6GGGg+hhdXgLAAEE9gEAAAQUAAAAUEsDBBQACAAIABZsM1QAAAAAAAAAAHwBAAAaACAAVGVzdCBBcmNoaXZlL1Rlc3QgRmlsZS5ydGZVVA0AB32D6GF9g+hhfYPoYXV4CwABBPYBAAAEFAAAAD2QwW7DIAyG73mKnHciFNJMPU6q9gA7+kKJSdEoRMZtIkV99xEi7cL/2/5+C3kDYteBidnXx85TJ7UEm2wyZST1IJqjYlw5WxN8nMTRmYNhl+ghNnApMt8COAEuLz5ncPZuKCOL9hvDC9lbc3k3W0mGRAW9AOEotYaJEONubuGJRSv1AbjOJo44/vOlDw9DU+iUEtVRdS+Py9J1Wh72PihxuF8fx/L52dAIvJ7LnNea4FV2fZVh2OXUi13UqSJaVESfK9KripR0lc+KDH1B9r2eouEnmbBX7Kc7Y3TGciLRNMcxpGrBOtH+YOb26gO2X+VWGPn9B1BLBwhb5lBh9gAAAHwBAABQSwMEFAAIAAgAFmwzVAAAAAAAAAAAbgEAACUAIABfX01BQ09TWC9UZXN0IEFyY2hpdmUvLl9UZXN0IEZpbGUucnRmVVQNAAd9g+hhfYPoYYmD6GF1eAsAAQT2AQAABBQAAABjYBVjZ2BiYPBNTFbwD1aIUIACkBgDJxAbMTAw2gBpIJ8xjwEZCDDgAI4hIUEQFljHGyBuQlPCDBUHmiGVnJ+rl1hQkJOql5NYXFJanJqSkliSqhwQDFX7B4g9GBj4EepyE5NzIOa7AAktBgYVJLnUkkSg/kSr+GxfF8+S1NzQ4tSikMT0YgaGyuYXiSBdLfHvoI64WHc42oLL8cqG8nVf9F1XcOPyEX6QVJCTWVxiYLCAA+prRqgEI5pCTgBQSwcIY9imG70AAABuAQAAUEsBAhQDFAAAAAAAFmwzVAAAAAAAAAAAAAAAAA0AIAAAAAAAAAAAAO1BAAAAAFRlc3QgQXJjaGl2ZS9VVA0AB32D6GF9g+hhhoPoYXV4CwABBPYBAAAEFAAAAFBLAQIUAxQACAAIABZsM1Rb5lBh9gAAAHwBAAAaACAAAAAAAAAAAACkgUsAAABUZXN0IEFyY2hpdmUvVGVzdCBGaWxlLnJ0ZlVUDQAHfYPoYX2D6GF9g+hhdXgLAAEE9gEAAAQUAAAAUEsBAhQDFAAIAAgAFmwzVGPYphu9AAAAbgEAACUAIAAAAAAAAAAAAKSBqQEAAF9fTUFDT1NYL1Rlc3QgQXJjaGl2ZS8uX1Rlc3QgRmlsZS5ydGZVVA0AB32D6GF9g+hhiYPoYXV4CwABBPYBAAAEFAAAAFBLBQYAAAAAAwADADYBAADZAgAAAAA=',
};

export const corelliumLicenseData: Licensing.VendorResponses.Corellium = {
    login_url: 'https://sans.enterprise.corellium.com/login',
    data: {
        id: 'None',
        email_address: TEST_ACCOUNT_RESPONSE.data.sansEmail,
        course_name: 'SEC575',
        user_id: TEST_ACCOUNT_RESPONSE.data.sansAccountID,
        enrollment: {
            invite_id: '1-2-3-4-5',
        },
    },
};

export const idaproLicenseData: Licensing.VendorResponses.IdaPro = {
    data: null,
    success: true,
    created_at: '2022-06-01T12:34:56+0000',
};

// Version 1 Handlers (Direct connection to Licensing Service)
const directHandlers = [
    http.post(
        `${config.services.sansLicensingService.baseUrl}/:license`,
        async ({ params }) => {
            const { license } = params;
            await delay(defaultDelayMs);
            switch (license) {
                // The following licenses share the same response structure
                case 'internal-test-suite':
                case 'arsenal':
                case 'shodan':
                case 'maltego':
                    return HttpResponse.json(mockStandardLicensingResponse);
                // The following licenses have unique response structures
                case 'cellebrite':
                    return HttpResponse.json(cellebriteLicenseData);
                case 'corellium':
                    return HttpResponse.json(corelliumLicenseData);
                case 'idapro':
                    return HttpResponse.json(idaproLicenseData);
                case 'chainalysis':
                    return HttpResponse.json(chainalysisLicenseData);
                default:
                    // If we got an invalid license endpoint, return 404
                    return HttpResponse.text('Not found', { status: 404 });
            }
        }
    ),
    http.post(
        `${config.services.sansLicensingService.baseUrl}/course/SEC540`,
        async () => {
            await delay(defaultDelayMs);
            return HttpResponse.json({
                data: {
                    course_name: 'SEC540',
                    provider: 'AWS',
                },
                success: true,
                created_at: '2022-06-01T12:34:56+0000',
            });
        }
    ),
];

// Version 2 Handlers (Proxy to MyLabs Service)
const proxyRequestHandler = http.post(
    config.services.mylabsService.baseUrl + '/licensing',
    async ({ request }) => {
        const { license } = (await request.json()) as { license: string };
        let responseObject: Licensing.V2.LicensingServiceResponse;
        const baseResponse = mockLicensingResponseV2;
        await delay(defaultDelayMs);
        switch (license) {
            case 'test-license':
                responseObject = {
                    ...baseResponse,
                };
                break;
            case 'arsenal':
            case 'shodan':
            case 'maltego':
                responseObject = {
                    ...baseResponse,
                    licenseCode:
                        mockStandardLicensingResponse.license.license_code,
                };
                break;
            case 'cellebrite':
                responseObject = {
                    ...baseResponse,
                    licenseData: {
                        // eslint-disable-next-line max-len
                        v2c: cellebriteLicenseData.license,
                    },
                };
                break;
            case 'corellium':
                responseObject = {
                    ...baseResponse,
                    licenseData: corelliumLicenseData.data,
                };
                break;
            case 'idapro':
                responseObject = {
                    ...baseResponse,
                    licenseCode: null,
                };
                break;
            case 'chainalysis':
                responseObject = {
                    ...baseResponse,
                    licenseCode: null,
                    licenseData:
                        chainalysisLicenseData.license.reactor_credentials,
                };
                break;

            default:
                return HttpResponse.text('Not Found', { status: 404 });
        }

        return HttpResponse.json(responseObject);
    }
);

export default { directHandlers, proxyRequestHandler };
