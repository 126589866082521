type LicenseDisplayWithChildrenProps = {
    children: string | JSX.Element;
};

/**
 * Display arbitrary data for a license.
 */
const LicenseDisplayWithChildren = ({ children }: LicenseDisplayWithChildrenProps) => {
    return (
        <section className="licensing-display">
            {children}
        </section>
    );
};

export default LicenseDisplayWithChildren;
